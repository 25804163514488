import "./style.css";
const { v4: uuidv4 } = require('uuid');

import { hidePage, router, showPage } from "./router";
function showError(e) {
}
let verifyMobileAndEmailState = {};
let hostServer = "https://account.cproll.com";
(function () {
//   document.getElementById('menuButton').addEventListener('click', function() {
//     document.getElementById('mySideMenu').classList.add('show');
// });
// document.getElementById('closeBtn').addEventListener('click', function() {
//   document.getElementById('mySideMenu').classList.remove('show');
// });
document.getElementById('menuButton').addEventListener('click', function(event) {
  event.stopPropagation(); // Prevents the click event from bubbling up to the document
  let sideMenu = document.getElementById('mySideMenu');
  if (sideMenu.classList.contains('show')) {
      sideMenu.classList.remove('show');
  } else {
      sideMenu.classList.add('show');
  }
});

document.getElementById('closeBtn').addEventListener('click', function(event) {
  event.stopPropagation(); // Prevents the click event from bubbling up to the document
  document.getElementById('mySideMenu').classList.remove('show');
});

document.addEventListener('click', function(event) {
  let sideMenu = document.getElementById('mySideMenu');
  if (!sideMenu.contains(event.target)) { // Check if the click was outside the side menu
      sideMenu.classList.remove('show');
  }
});


  const homePage = "HOME_PAGE";
  const signUpPage = "SIGN_UP_PAGE";
  const registerSuccess = "REGISTER_SUCCESS"
  let subscriptionPlanCode = "FREE";
  let toastTimer = null;
  let myRouter = router.init({
    routes: [
      {
        route: ["", "home_section", "features_section", "contact_section"],
        view: (data) => {
          gotoPage(homePage);
        },
      },
      {
        route: "subscription_plans",

        view: (data) => {
          // if (window.location.hash !== "") {
          //   window.location.hash = "";
          // }
          gotoPage(homePage);
          setTimeout(() => {
            window.location.hash = "pricing_section";
          }, 0);
        },
      },
      {
        route: "signup_section",

        view: (data) => {
          gotoPage(signUpPage);
          showPlanDetails();
        },
      },

      {
        route: "register_success",

        view: (data) => {
          gotoPage(registerSuccess);
        },
      },

    ],
  });

  window.addEventListener("load", (e) => {
    // hidePage(signUpPage);
    initEvents();
  });

  function initEvents() {
    let backButton = document.querySelector(".goto_home_page_btn");
    backButton.addEventListener("click", (e) => {
      window.location.hash = "";
    });
    let signUpBtns = document.querySelectorAll(".goto_sign_up_page_btn");
    signUpBtns.forEach((btn) => {
      btn.addEventListener("click", (e) => {
        subscriptionPlanCode = e.target.getAttribute("data-plan") || "FREE";
        window.location.hash = "signup_section";
        showPlanDetails();
      });
    });

    let passwordEyeBtns = document.querySelectorAll(".password_eye_icon_cont");
    passwordEyeBtns.forEach((btn) => {
      btn.setAttribute("type", "password");
      btn.addEventListener("click", (e) => {
        let passwordField = e.target.closest(".password_field");
        passwordField.classList.toggle("password_hide");
        if (passwordField.classList.contains("password_hide")) {
          passwordField.querySelector("input").setAttribute("type", "password");
        } else {
          passwordField.querySelector("input").setAttribute("type", "text");
        }
      });
    });

    let registerBtn = document.getElementById("register_btn");
    registerBtn.addEventListener("click", (e) => {
      validateUserDetails();
    });

    let toastCloseBtn = document.getElementById("toast_close_btn");
    toastCloseBtn.addEventListener("click", (e) => {
      resetToast();
    });
  }
  async function validateUserDetails() {
    let formFields = document.querySelectorAll(
      "#signUpFormCont input, #signUpFormCont select"
    );
    formFields = Array.from(formFields);
    let requiredFields = formFields.filter(
      (field) => field.hasAttribute("required") && !field.value
    );

    if (requiredFields.length > 0) {
      requiredFields.forEach((field) => {
        field.classList.add("required");
      });
      return;
    }

    let invalidFields = formFields.filter((field) => !field.validity.valid);
    if (invalidFields.length > 0) {
      invalidFields.forEach((field) => {
        field.classList.add("invalid");
      });
      return;
    }

    let passwords = formFields.filter(
      (field) => field.name === "password" || field.name === "confirmPassword"
    );
    if (passwords[0].value.trim() !== passwords[1].value.trim()) {
      formFields
        .filter((field) => field.name === "confirmPassword")[0]
        .classList.add("password");
      return;
    }
    let payload = formFields.reduce((acc, field) => {
      if (field.type === "checkbox") {
        acc[field.name] = field.checked ? true : false;
        return acc;
      }
      if (field.name === "confirmPassword") return acc;
      acc[field.name] = field.value.trim();
      return acc;
    }, {});
    payload["subscriptionPlanCode"] = subscriptionPlanCode;
    if(!verifyMobileAndEmailState.verifiedMobileNO && !verifyMobileAndEmailState.verifiedEmail){
      return;
    }
    payload.mobile = verifyMobileAndEmailState.verifiedMobileNO;
    payload.email = verifyMobileAndEmailState.verifiedEmail;
    payload.sessionId = sessionId;

    let host = window.location.origin;
    let endPoint = "/apip/organization-registration/create";
    let res = await registerUser(endPoint, payload);
    hideLoader();
    if (res.statusCode == 440) {
      toastMessage(res.showMessage, "error");
      reLoadFormVerifiction();
      return;
    }
    if (res.statusCode != 200) {
      toastMessage(res.showMessage, "error");
      return;
    }
    toastMessage(res.showMessage, "success");
    setTimeout(function () {
      window.location.hash = "register_success";
      reLoadFormVerifiction()
    }, 1000);
  }

  async function registerUser(url, data) {
    if (!url || !data) return;
    showLoader()
    const response = await fetch(hostServer+url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  function showPlanDetails() {
    let planPrice = 0;
    if (subscriptionPlanCode === "BASIC") {
      planPrice = 500;
    }
    if (subscriptionPlanCode === "ADVANCED") {
      planPrice = 700;
    }
  }

  function toastMessage(message, type) {
    if (toastTimer) {
      clearTimeout(toastTimer);
    }
    if(type == "success"){
      document.getElementById("tost_message_Container").classList.remove("border", "border-red-400", "bg-red-100", "px-4", "py-3")
      document.getElementById("toast_msg_text").classList.remove("text-red-900")  
      document.getElementById("tost_message_Container").classList.add("border", "border-green-400", "bg-green-100", "px-4", "py-3")

      document.getElementById("toast_msg_text").classList.add("text-green-900")  
     }
    if(type == "error"){
      document.getElementById("tost_message_Container").classList.remove("border", "border-green-400", "bg-green-100", "px-4", "py-3")
      document.getElementById("toast_msg_text").classList.remove("text-green-900")  
      document.getElementById("tost_message_Container").classList.add("border", "border-red-400", "bg-red-100", "px-4", "py-3")
      document.getElementById("toast_msg_text").classList.add("text-red-900")
    }
    let toast = document.getElementById("toast-message");
    let toastMsg = document.getElementById("toast_msg_text");
    toastMsg.textContent = message;
    toast.classList.add(type);
    toast.classList.remove("hidden");
    toastTimer = setTimeout(() => {
      resetToast();
    }, 10000);
  }

  function resetToast() {
    let toast = document.getElementById("toast-message");
    let toastMsg = document.getElementById("toast_msg_text");
    toastMsg.textContent = "";
    toast.classList.remove("success", "warn", "error");
    toast.classList.add("hidden");
  }

  function gotoPage(id) {
    if (id === signUpPage) {
      showPage(signUpPage);
      hidePage(homePage);
      hidePage(registerSuccess)
      return;
    }
    if(id ===homePage ){
      showPage(homePage);
      hidePage(signUpPage);
      hidePage(registerSuccess)
      return;
    }
    if(id === registerSuccess){
    showPage(registerSuccess)
    hidePage(homePage);
    hidePage(signUpPage);
    }
  }
   let remainingTime = 10
   let sessionId;
   var timerInterval;
   let resendDisplayforMobile = document.getElementById("resendDisplayforMobile")
   let timerDisplayforMobile = document.getElementById("timerDisplayforMobile")
   let resendDisplayforEmail = document.getElementById("resendDisplayforEmail")
   let timerDisplayforEmail = document.getElementById("timerDisplayforEmail")
   var mobileOTPValue = ""
   var emailOTPValue = ""

   let resendMobileOTPBtn = document.getElementById("resendMobileOTPBtn")
   resendMobileOTPBtn.addEventListener("click", (e) => {
     resendMobileOTPfn();
   })

   let resendEmailOTPBtn = document.getElementById("resendEmailOTPBtn")
   resendEmailOTPBtn.addEventListener("click", (e) => {
     resendEmailOTPfn();
   })

   //Mobile timer to resend OTP
  function updateMobileTimer() {
    if (remainingTime > 0) {
      remainingTime--;
      updateTimerDisplay();
    }
    // if(remainingTime == 0){
      
    // }
    
    if(remainingTime == 0){
      clearInterval(timerInterval)
      resendDisplayforMobile.classList.remove("hidden")
      timerDisplayforMobile.classList.add("hidden")
    }
  }


  function updateTimerDisplay() {
    document.getElementById("remainingTimeContainerForMobile").innerHTML = remainingTime;
  }


   //Email timer to resend OTP
  function updateEmailTimer() {
    if (remainingTime > 0) {
      remainingTime--;
      updateTimerDisplayEmail();  
    }
    else{
      clearInterval(timerInterval);
    }
    
    if(remainingTime == 0){
      resendDisplayforEmail.classList.remove("hidden")
      timerDisplayforEmail.classList.add("hidden")
    }
  }


  function updateTimerDisplayEmail() {
    document.getElementById("remainingTimeContainerForMail").innerHTML = remainingTime;
  }

  // Send Mobile OTP
  let getMobileOTP = document.getElementById("getMobileOTP_btn");
    getMobileOTP.addEventListener("click", (e) => {
      sendMobileOTPFn()
    });

   async function sendMobileOTPFn(){
      let phoneNo = document.getElementById("phoneNo")
      generateRandomNumber();
      let flag = verifyFormData({phoneNo})
      if(flag){
        getMobileOTP.classList.add("disabled")
        const svgInsideButton = document.getElementById('getMobileOTP_svg');
        svgInsideButton.classList.remove("hidden")
        let res = await getMobileOTPApiCall(phoneNo.value);
        svgInsideButton.classList.add("hidden")
        getMobileOTP.classList.remove("disabled")
       if (res.statusCode !== 200) {
          toastMessage(res.showMessage, "error");
          return
        
        }

        document.getElementById("mobileOTPContainer").classList.remove("hidden")
        document.getElementById("signupMobileNoContainer").classList.add("hidden")
        document.getElementById("mobileAndEmailVerificationData").innerHTML=`<div><h3 class="font-semibold text-lg" ></h3><p class=" text-slate-400">we have sent verification code to<span class="text-slate-700"> ${verifyMobileAndEmailState.mobileNO}</span><span id="editMobileNo" class="ml-1 text-slate-700" ><span class="fa fa-edit"><span></span></p></div>`
        let editMobileNo = document.getElementById("editMobileNo");
        editMobileNo.addEventListener("click", (e) => {
          editMobileNofn()
      
        });
        clearInterval(timerInterval);
        timerInterval = setInterval(updateMobileTimer, 1000);
     }

    }

   // Edit mobile no
    function editMobileNofn(){
      clearInterval(timerInterval);
      document.getElementById("mobileOTPContainer").classList.add("hidden")
      document.getElementById("signupMobileNoContainer").classList.remove("hidden")
        document.getElementById("mobileAndEmailVerificationData").innerHTML= '';
      let phoneNo = document.getElementById("phoneNo")
      phoneNo.value = verifyMobileAndEmailState.mobileNO;
      remainingTime =10;
    }
    let verifyMobileOTP = document.getElementById("verifyMobileOTP");
    verifyMobileOTP.addEventListener("click", (e) => {
      verifyMobileOTPFn()
    });

    // Resend Mobile OTP
    function resendMobileOTPfn(){
      clearMobileOtpInputs();
      resendDisplayforMobile.classList.add("hidden")
      timerDisplayforMobile.classList.remove("hidden")
      clearInterval(timerInterval);
      remainingTime = 10;
      timerInterval = setInterval(updateMobileTimer, 1000);
      sendMobileOTPFn()
    }

// Verify Mobile OTP
   async function verifyMobileOTPFn(){
     var otpNo = mobileOTPValue
      let flag = verifyFormData({otpNo})
      if(flag){

        verifyMobileOTP.classList.add("disabled")
        const svgInsideButton = document.getElementById('verifyMobileOTP_svg');
        svgInsideButton.classList.remove("hidden")

       let res = await verifyMobileOTPApiCall(otpNo);

       svgInsideButton.classList.add("hidden")
       verifyMobileOTP.classList.remove("disabled")

        if (res.statusCode !== 200 || res.status != "success") {
          toastMessage(res.showMessage, "error");
          return false;
        }
       mobileOTPValue="";
      document.getElementById("phoneNoContainer").textContent = "+91"+verifyMobileAndEmailState.mobileNO;
      verifyMobileAndEmailState.verifiedMobileNO = verifyMobileAndEmailState.mobileNO;
      changeStep(2)
      showEmailPage();
     }
     else{
      toastMessage( "please enter valid OTP", "error");
      return false;
    }
    }


    async function getMobileOTPApiCall(mobileNO ){
      let url = "/apip/otp-verification/send-sms-otp"
      let payload = {
        "mobile": mobileNO,
        "sessionId":sessionId
        }

      const response = await fetch(hostServer+url, {
       method: "POST",
       mode: "cors",
       cache: "no-cache",
       credentials: "omit",
       headers: {
         "Content-Type": "application/json",
       },
       redirect: "follow",
       referrerPolicy: "no-referrer",
       body: JSON.stringify(payload),
     });
     return response.json();
 }


 async function verifyMobileOTPApiCall(otp){
   let url = "/apip/otp-verification/verify-sms-otp"
   let payload = {
     "mobile": verifyMobileAndEmailState.mobileNO,
     "otp" : otp,
     "sessionId":sessionId
   }
   const response = await fetch(hostServer+url, {
     method: "POST",
     mode: "cors",
     cache: "no-cache",
     credentials: "omit",
     headers: {
       "Content-Type": "application/json",
     },
     redirect: "follow",
     referrerPolicy: "no-referrer",
     body: JSON.stringify(payload),
   });
   return response.json()

 }


    //EMAIL

    function showEmailPage(){

      document.getElementById("mobileAndEmailVerificationData").innerHTML= '';
      document.getElementById("signupMobileNoAndOTPContainer").classList.add("hidden")
      document.getElementById("signUpFormCont").classList.add("hidden")
      document.getElementById("signupEmailAndOTPContainer").classList.remove("hidden")
      clearInterval(timerInterval);
      remainingTime = 10
   }
    //Send Email OTP
    let getEmailOTP = document.getElementById("getEmailOTP_btn");
    getEmailOTP.addEventListener("click", (e) => {
      sendEmailOTPFn();
    });
   async function sendEmailOTPFn(){
     let emailAddress = document.getElementById("emailAddress")
     let flag = verifyFormData({emailAddress})
     if(flag){
      getEmailOTP.classList.add("disabled")
      const svgInsideButton = document.getElementById('getEmailOTP_svg');
      svgInsideButton.classList.remove("hidden")

      let res = await getEmailOTPApiCall(emailAddress.value);

     svgInsideButton.classList.add("hidden")
     getEmailOTP.classList.remove("disabled")
      
    
     if (res.statusCode !== 200) {
      toastMessage(res.showMessage, "error");
      return
    
    }
  
        document.getElementById("signupEmailContainer").classList.add("hidden");
        document.getElementById("emailOTPContainer").classList.remove("hidden");
        document.getElementById("mobileAndEmailVerificationData").innerHTML=`<div><h3 class="font-semibold text-lg" ></h3><p class=" text-slate-400">we have sent verification code to<span class="text-slate-700"> ${verifyMobileAndEmailState.email}</span><span id="editEmailAddress" class="ml-1 text-slate-700" ><span class="fa fa-edit"><span></span></p></div>`
        let editEmailAddress = document.getElementById("editEmailAddress");
        editEmailAddress.addEventListener("click", (e) => {
          editEmailAddressfn()
      
        });
        clearInterval(timerInterval);
        timerInterval = setInterval(updateEmailTimer, 1000);
     }
    }  
    // Resend Email OTP
    function resendEmailOTPfn(){
      clearEmailOtpInputs()
      document.getElementById("timerDisplayforEmail").classList.remove("hidden")
      document.getElementById("resendDisplayforEmail").classList.add("hidden")
      clearInterval(timerInterval);
      remainingTime = 10;
      timerInterval = setInterval(updateEmailTimer, 1000);
     sendEmailOTPFn()
      
    }     

   // Edit Email Address
    function editEmailAddressfn(){
      remainingTime = 10;
      clearInterval(timerInterval);
      document.getElementById("emailOTPContainer").classList.add("hidden");
      document.getElementById("signupEmailContainer").classList.remove("hidden");
      document.getElementById("mobileAndEmailVerificationData").innerHTML= '';
      let emailAddress = document.getElementById("emailAddress")
      emailAddress.value = verifyMobileAndEmailState.email;
      
    }

   // Verify Email OTP 
    let verifyEmailOTP = document.getElementById("verifyEmailOTP");
    verifyEmailOTP.addEventListener("click", (e) => {
      verifyEmailOTPFn()

    });
    async function verifyEmailOTPFn(){
      var otpNo = emailOTPValue;
      
      let flag = verifyFormData({otpNo})
      if(flag){
        verifyEmailOTP.classList.add("disabled")
        const svgInsideButton = document.getElementById('verifyEmailOTP_svg');
        svgInsideButton.classList.remove("hidden")

        let res =   await verifyEmailOTPApiCall(otpNo);

       svgInsideButton.classList.add("hidden")
       verifyEmailOTP.classList.remove("disabled")

       
       if (res.statusCode !== 200 || res.status == "false") {
        toastMessage(res.showMessage, "error");
        return false;
      }
      emailOTPValue="";
      document.getElementById("emailIdContainer").textContent =  verifyMobileAndEmailState.email;
      verifyMobileAndEmailState.verifiedEmail = verifyMobileAndEmailState.email
      changeStep(3)
      showRegisterPage();
      }
      else{
        toastMessage("please enter valid OTP", "error");
        return false;
      }
     }
     function showRegisterPage(){
      document.getElementById("mobileAndEmailVerificationData").innerHTML= ''; 
      document.getElementById("signupEmailAndOTPContainer").classList.add("hidden")
      document.getElementById("signupMobileNoAndOTPContainer").classList.add("hidden")
      document.getElementById("signUpFormCont").classList.remove("hidden")
      document.getElementById("signupEmailContainer").classList.remove("hidden");
      document.getElementById("emailOTPContainer").classList.add("hidden");
      clearInterval(timerInterval);
      remainingTime = 10
     }

     
    function verifyFormData(data){

      if (data.phoneNo){
        let phoneNumber = data.phoneNo.value.trim();
        if(!data.phoneNo.value){
          data.phoneNo.classList.add("required");
          return false
        }
        if(phoneNumber.length == 10 && !isNaN(phoneNumber)){
          verifyMobileAndEmailState.mobileNO = phoneNumber
             return true;
        }
        data.phoneNo.classList.add("invalid");
        return false
      }
      if(data.emailAddress){
        let email = data.emailAddress.value.trim();
        if(!email){
          data.emailAddress.classList.add("required");
          return false
        }
        if (data.emailAddress.validity.valid) {
          verifyMobileAndEmailState.email = email
          return true;
      }
        data.emailAddress.classList.add("invalid");
        return false

      }

      if(data.otpNo){
        if(data.otpNo.length == 6 && !isNaN(data.otpNo)){
          return true;
        }
        return false
      }
      return false;
      
    }
       

   async function getEmailOTPApiCall(email){
      let payload = {"email": email,"sessionId":sessionId}
      let url = "/apip/otp-verification/send-email-otp"

      const response = await fetch(hostServer+url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(payload),
      });
      return response.json()
    }


    async function verifyEmailOTPApiCall(emailOTP){
      let payload = { 
        "email": verifyMobileAndEmailState.email,
         "otp" : emailOTP,
         "sessionId":sessionId
       }
      let url = "/apip/otp-verification/verify-email"

      const response = await fetch(hostServer+url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(payload),
      });
      return response.json()
    }

    function changeStep(stepNumber) {
      // Get all steps
      var steps = document.querySelectorAll('.md-step');
      
      // Remove 'active' class from all steps
      if(!stepNumber){
      steps.forEach(function(step) {
        step.classList.remove('active');
        step.classList.remove('done');
      });
      document.getElementById('step1').classList.add('active')
      }
      
      // Add 'active' class to the selected step
      var selectedStep = document.getElementById('step' + stepNumber);
      var verifiedStep = stepNumber -1;
      if(verifiedStep>=1){
        document.getElementById('step' + verifiedStep).classList.add('done')
      }
      selectedStep.classList.add('active');
  }
    function generateRandomNumber() {
       sessionId = uuidv4();
  } 

  // reload form
  function reLoadFormVerifiction(){
    changeStep();
    var forms = document.querySelectorAll('form');

    forms.forEach(function(form) {
        for (var i = 0; i < form.elements.length; i++) {
            var element = form.elements[i];
            if (element.type !== 'button' && element.type !== 'submit' && element.type !== 'reset') {
              if (!element.readOnly){
                element.value = '';
              }
               
            }
        }
    });
    document.getElementById("countryCodeInputBox").value= "+91";
        loadMobileOTPVerifictionPage();
  }

  function loadMobileOTPVerifictionPage(){
    document.getElementById("signUpFormCont").classList.add("hidden")
    document.getElementById("mobileOTPContainer").classList.add("hidden")
    document.getElementById("emailOTPContainer").classList.add("hidden")
    document.getElementById("signupMobileNoContainer").classList.remove("hidden")
    document.getElementById("signupMobileNoAndOTPContainer").classList.remove("hidden")
  }

  function showLoader() {
    const loaderContainer = document.getElementById('loader-container');
    loaderContainer.style.display = 'block';
  }
  
  function hideLoader() {
    const loaderContainer = document.getElementById('loader-container');
    loaderContainer.style.display = 'none';
  }
  

  let loginPage = document.getElementById("loginPage_btn");
  loginPage.addEventListener("click", (e) => {
    goToLoginPagefn()
  });
  function goToLoginPagefn(){
      window.location = hostServer+"/login";
  }
   
  let goTOHomePage = document.getElementById("homePage_btn");
  goTOHomePage.addEventListener("click", (e) => {
    goToHomePagefn()
  });
  function goToHomePagefn(){
    gotoPage(homePage);
  }

    var otpInputs = document.querySelectorAll(".otp-input");
    var emailOtpInputs = document.querySelectorAll(".email-otp-input");
  
    function setupOtpInputListeners(inputs) {
      inputs.forEach(function (input, index) {
        input.addEventListener("paste", function (ev) {
          var clip = ev.clipboardData.getData("text").trim();
          if (!/^\d{6}$/.test(clip)) {
            ev.preventDefault();
            return;
          }

          var characters = clip.split("");
          inputs.forEach(function (otpInput, i) {
            otpInput.value = characters[i] || "";
          }); 
          enableNextBox(inputs[0], 0);
          inputs[5].removeAttribute("disabled");
          inputs[5].focus();
          updateOTPValue(inputs);
        });
  
        input.addEventListener("input", function () {
          var currentIndex = Array.from(inputs).indexOf(this);
          var inputValue = this.value.trim();
  
          if (!/^\d$/.test(inputValue)) {
            this.value = "";
            return;
          }
  
          if (inputValue && currentIndex < 5) {
            inputs[currentIndex + 1].removeAttribute("disabled");
            inputs[currentIndex + 1].focus();
          }
  
          if (currentIndex === 4 && inputValue) {
            inputs[5].removeAttribute("disabled");
            inputs[5].focus();
          }
  
          updateOTPValue(inputs);
        });
  
        input.addEventListener("keydown", function (ev) {
          var currentIndex = Array.from(inputs).indexOf(this);
  
          if (!this.value && ev.key === "Backspace" && currentIndex > 0) {
            inputs[currentIndex - 1].focus();
          }
        });
      });
    }
  
    function updateOTPValue(inputs) {
      var otpValue = "";
      inputs.forEach(function (input) {
        otpValue += input.value;
      });
      if (inputs === otpInputs) {
        mobileOTPValue = otpValue;
      } 
      else if (inputs === emailOtpInputs) {
        emailOTPValue = otpValue;
      }
    }
  
    // Setup listeners for OTP inputs
    setupOtpInputListeners(otpInputs);
    setupOtpInputListeners(emailOtpInputs);
  
    otpInputs[0].focus();
    emailOtpInputs[0].focus();
    
    
    //clear mobile otp input box
    function clearMobileOtpInputs() {
      mobileOTPValue = "";  
      const inputs = document.querySelectorAll('.mobile-otp .otp-input');
      inputs.forEach(input => {
        input.value = '';
      });
      inputs[0].focus();
    }
  //clear email otp input box
    function clearEmailOtpInputs() {
      emailOTPValue = "";  
      const inputs = document.querySelectorAll('.email-otp .otp-input');
      inputs.forEach(input => {
        input.value = '';
      });
      inputs[0].focus();
    }
})();