const router = (function (_name) {
  let router = {};
  router._name = `javascript ${_name}`;
  router._version = "1.0.0";
  router.config = {};
  router.definedRoutes = [];
  router.currentRoute = window.location.href;
  router.origin = window.location.origin;
  router.currentHash = window.location.hash;

  const logit = (...args) => {
    console.log("[ROUTER] ", ...args);
  };

  window.addEventListener("load", (e) => {});

  function initEvents() {
    window.addEventListener("hashchange", (e) => {
      // logit(e);
      resetCurrentRoute();
      setTimeout(showView, 0);
    });
  }
  function resetCurrentRoute() {
    router.currentRoute = window.location.href;
    router.origin = window.location.origin;
    router.currentHash = window.location.hash;
  }

  function init(config) {
    router.config = config || {};
    router.definedRoutes = config.routes || [];
    logit("router is initialized");
    showView();
    initEvents();
  }

  function showView() {
    let route = router.definedRoutes.find((view) => {
      if (Array.isArray(view.route)) {
        let route = view.route.find(
          (route) => route === router.currentHash.replace("#", "")
        );
        return route === "" || route ? true : false;
      }
      return view.route === router.currentHash.replace("#", "");
    });
    if (route && route.view) setTimeout(route.view(), 0);
  }

  router.init = init;
  return router;
})("router");

function showPage(id) {
  let page = document.getElementById(id);
  page.classList.remove("hidden");
}
function hidePage(id) {
  let page = document.getElementById(id);
  page.classList.add("hidden");
}

export { router, showPage, hidePage };
